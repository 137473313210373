import { Text } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import SortTableComponent from '@app/components/elements/table/SortTable/SortTable';
import TableHeader from '@app/components/elements/table/SortTable/TableHeader/TableHeader';
import TableRowComponent from '@app/components/elements/table/TableRow/TableRow';
import DownloadIconClickable from '@app/components/icons/DownloadIcon';
import { formatISODateSlashandAMPM } from '@app/helpers/datetime';
import { analyticsType } from '@app/models/marleyTypes';
import './DataDownload.less';
import { StateType } from '@app/types/reducer-state';
import { Tooltip } from '@chakra-snippets/tooltip';

const columns: { label: string; location: string; id?: string }[] = [
  { label: 'Link', location: 'icon' },
  { label: 'Report', location: 'report' },
  { label: 'Last Run', location: 'lastRun' },
];
const dlColumns = columns.map((col) => (
  <TableHeader
    className={col.location}
    key={col.id || col.label}
    {...col}
    tableId="data-dl"
    sortLocation={null}
    numericSort={false}
    type={null}
  />
));
const buildReportNameComponent = (reportName: string, lastRun: string) => {
  let tooltip;
  if (lastRun) {
    tooltip = `This report is updated every day at ${lastRun.split(',')[1]}`;
  }
  return (
    tooltip
      ? <Tooltip showArrow positioning={{ placement: 'top' }} content={tooltip}><Text>{reportName}</Text></Tooltip>
      : lastRun
  );
};

interface DataDownloadProps {
  reports: {
    name: string;
    link: string;
    lastRun: string;
  }[];
}

const DataDownload = (props: DataDownloadProps) => {
  const { reports } = props;
  const uiRows = reports.map((reportInfo) => {
    const reportName = _.get(reportInfo, 'name', 'Default');
    const lastRun = formatISODateSlashandAMPM(_.get(reportInfo, 'lastRun')) || '';
    const icon = <DownloadIconClickable link={reportInfo.link} />;
    const uiRow = {
      key: reportName,
      id: reportName,
      lastRun: lastRun || '--',
      icon,
      report: buildReportNameComponent(reportName, lastRun),
    };
    return (
      <TableRowComponent
        className="data-download-row"
        columns={columns}
        item={uiRow}
        key={reportName}
      />
    );
  });
  return (
    <div className="data-download-table">
      <SortTableComponent
        isStandard
        columns={dlColumns}
        rows={uiRows}
        type={analyticsType}
      />
    </div>
  );
};

const mapStateToProps = (state: StateType) => ({
  reports: _.get(state, 'analytics.reports', []),
});

export default connect(mapStateToProps)(DataDownload);
